<script lang="ts" setup>
import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';
import type {
	EntityData,
	EntityXProductData,
	PageSectionResponse,
	ProductsData,
} from '@laam/cms-shared';
import { fetchSections } from '~/data/pageschema';
import {
	keepPreviousData,
	useInfiniteQuery,
	useQuery,
} from '@tanstack/vue-query';
import {
	CategoryGrid,
	EntityCarousel,
	EntityGrid,
	ProductsCarousel,
	ProductsGrid,
	CategoryCarousel,
	CategoryCarouselScrollable,
	CategoryGridScrollable,
} from '@laam/ui/cms';
import EntityCard from '~/components/homepage/octane/EntityCard.vue';
import HeroBanner from '~/components/homepage/octane/HeroBanner.vue';
import EntityXProductsCarousel from '~/components/homepage/octane/EntityXProductsCarousel.vue';
import EntityXProductsGrid from '~/components/homepage/octane/EntityXProductsGrid.vue';
import ProductCard from '~/components/ProductCard.vue';
import CategoryCard from '~/components/homepage/octane/CategoryCard.vue';
import TrendingProducts from '~/components/homepage/TrendingProducts.vue';
import MiniBanner from '~/components/homepage/octane/MiniBanner.vue';
import { type MetaDataKeys, META_DATA } from '~/utils/constants';

definePageMeta({
	validate: (route) => {
		// First check if it's not a file
		const isFile =
			/^.*\.(jpg|jpeg|png|gif|ico|json|rss|xml|svg|js|css|mjs|woff|woff2|pdf)$/.test(
				route.fullPath,
			);
		if (isFile) return false;
		return true;
	},
});

const route = useRoute();

const pageRequestId = uuidv4();

const store = useGlobalStore();
const { storeType } = storeToRefs(store);

const pageIdentifier = computed(() => {
	if (!(route.name!.toString() === 'identifier')) return;
	if (!route.params) return;
	if (!route.params.identifier) {
		return 'homepage';
	} else if (Array.isArray(route.params.identifier)) {
		return route.params.identifier.join('-');
	} else return route.params.identifier;
});

const { $eventClient } = useNuxtApp();

const { countryCode } = useCountryCode();

const hasPageIdentifier = computed(() => !!pageIdentifier.value);

const {
	data: server,
	error,
	suspense,
} = useQuery({
	queryKey: ['server_page', pageIdentifier],
	queryFn: () =>
		fetchSections(
			pageRequestId,
			pageIdentifier.value!,
			undefined,
			countryCode,
			storeType.value!,
		),
	enabled: hasPageIdentifier,
	placeholderData: keepPreviousData,
});

const timerData = {
	mobile_url:
		'https://cdn.shopify.com/s/files/1/2337/7003/files/mobile-timmer-3-173281330009924.png',
	desktop_url:
		'https://cdn.shopify.com/s/files/1/2337/7003/files/desktop-timmer-3-3-173280873675651.png',
	link: {
		name: 'LAAM',
		href: 'https://laam.pk',
		id: 1,
		entity: 'banner',
	},
};

const difference = ref(-1);
provide('difference', difference);

onServerPrefetch(async () => {
	await suspense();
});

await suspense();

if (error.value) {
	throw createError({
		statusCode: 404,
		message: 'Page not found',
		data: {
			url: route.fullPath,
			error: error.value,
		},
		fatal: true,
	});
}

const {
	data: pagesData,
	hasNextPage,
	isFetchingNextPage,
	fetchNextPage,
} = useInfiniteQuery<PageSectionResponse>({
	queryKey: ['pageSchema', server.value?.pageRequestId],
	queryFn: ({ pageParam }) =>
		fetchSections(
			server.value!.pageRequestId,
			pageIdentifier.value!,
			pageParam as string,
			countryCode,
			storeType.value!,
		),
	getNextPageParam: (lastPage) => {
		if (!lastPage) {
			return null;
		}
		return lastPage.cursor == '' ? null : lastPage.cursor;
	},
	initialData: {
		pages: [server.value!],
		pageParams: [server?.value?.cursor!],
	},
	initialPageParam: 1,
	refetchOnWindowFocus: false,
	staleTime: 15 * 60 * 1000, // 15 minutes
	enabled: !!server.value,
});

watch(isFetchingNextPage, (isFetchingNextPage) => {
	if (isFetchingNextPage)
		$eventClient.sendEvent('homepage-pagination', {
			page_number: (pagesData.value?.pages.length || 0) + 1,
			location: window.location.href,
		});
});

const handleNextPage = async () => {
	if (hasNextPage.value && !isFetchingNextPage.value) {
		fetchNextPage();
	}
};

const { $cathodeClient } = useNuxtApp();

onMounted(() => {
	$cathodeClient.queueEvent('page_load', {
		source: `${window.location.origin}${route.path}`,
		statusCode: 200,
		page: 'Homepage',
		referrer: document.referrer,
	});
});

if (
	storeType.value !== 'OCTANE' &&
	pageIdentifier.value &&
	['homepage', 'women', 'men', 'kids'].includes(pageIdentifier.value)
) {
	useSeoMeta({
		title: META_DATA[pageIdentifier.value as MetaDataKeys].title,
		ogTitle: META_DATA[pageIdentifier.value as MetaDataKeys].title,
		description: META_DATA[pageIdentifier.value as MetaDataKeys].description,
		ogDescription: META_DATA[pageIdentifier.value as MetaDataKeys].description,
		titleTemplate: null,
	});
}

useInfiniteSroll(handleNextPage, 0.6);

const Logan = useLoganStore();

provide('storeType', storeType);

const nodeIdMapToIdentifier = {
	homepage: [1, 21, 41, 54],
	women: [1],
	men: [21],
	kids: [41, 54],
};
</script>

<template>
	<div class="homepage--components lg:py-8xl gap-3xl lg:gap-10xl flex flex-col">
		<h1 class="visually-hidden">LAAM</h1>
		<MiniBanner
			v-if="
				store.addOns?.['blessed-friday-sale']?.enabled &&
				difference !== 0 &&
				store.storeType === 'MARKETPLACE'
			"
			:data="timerData"
			:display-timer="store.addOns?.['blessed-friday-sale']?.enabled"
			:end-time="store.addOns?.['blessed-friday-sale']?.endTime"
		/>
		<template
			v-for="(page, idx) in pagesData!.pages"
			:key="pagesData!.pageParams[idx]"
		>
			<template
				v-for="(section, verticalIndex) in page.sections"
				:key="verticalIndex"
			>
				<HeroBanner
					v-if="section.component_identifier === 'hero-banner'"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				/>

				<EntityXProductsCarousel
					v-if="section.component_identifier === 'entity-x-products-carousel'"
					:metadata="cast<EntityXProductData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				/>

				<EntityXProductsGrid
					v-if="section.component_identifier === 'entity-x-products-grid'"
					:metadata="cast<EntityXProductData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				/>

				<EntityCarousel
					v-if="section.component_identifier === 'entity-carousel'"
					v-slot="{ entity, data: entityData, horizontalIndex }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				>
					<EntityCard
						:entity="entity"
						:data="entityData"
						:horizontal-index="horizontalIndex"
						:data-source="{
							dataSource: 'valhalla',
							clickedFrom: 'homepage',
							version: 'v0',
						}"
						:parent-title="section.meta_data.title!"
						:vertical-index="verticalIndex"
					/>
				</EntityCarousel>

				<EntityGrid
					v-if="section.component_identifier === 'entity-grid'"
					v-slot="{ entity, data: entityData, horizontalIndex }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				>
					<EntityCard
						:entity="entity"
						:data="entityData"
						:horizontal-index="horizontalIndex"
						:data-source="{
							dataSource: 'valhalla',
							clickedFrom: 'homepage',
							version: 'v0',
						}"
						:parent-title="section.meta_data.title!"
						:vertical-index="verticalIndex"
					/>
				</EntityGrid>

				<ProductsCarousel
					v-if="section.component_identifier === 'products-carousel'"
					v-slot="{ horizontalIndex, product }"
					:metadata="cast<ProductsData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				>
					<ProductCard
						:data="product"
						:index="horizontalIndex"
						:data-source="{
							dataSource: 'valhalla',
							clickedFrom: ['homepage'],
							version: 'v0',
						}"
						page="homepage"
						:horizontal-index="horizontalIndex"
						:vertical-index="idx"
						:is-hvc="Logan.isHvc"
					/>
				</ProductsCarousel>

				<ProductsGrid
					v-if="section.component_identifier === 'products-grid'"
					v-slot="{ horizontalIndex, product }"
					:metadata="cast<ProductsData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				>
					<ProductCard
						:data="product"
						:index="horizontalIndex"
						:data-source="{
							dataSource: 'valhalla',
							clickedFrom: ['homepage'],
							version: 'v0',
						}"
						page="homepage"
						:horizontal-index="horizontalIndex"
						:vertical-index="idx"
						:is-hvc="Logan.isHvc"
					/>
				</ProductsGrid>

				<CategoryGrid
					v-if="section.component_identifier === 'category-grid'"
					v-slot="{ index, data }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				>
					<CategoryCard
						v-if="data.href !== 'all' && data.image"
						:data="data"
						:redirect="collectionToNode(data.href)"
						:image-src="data.image"
						:title="data.name"
						:horizontal-index="index"
						:vertical-index="idx"
						:data-source="{
							dataSource: 'manual',
							version: '',
							clickedFrom: 'homepage',
						}"
						shape="square"
					/>
				</CategoryGrid>

				<CategoryCarousel
					v-if="section.component_identifier === 'category-carousel'"
					v-slot="{ index, data }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				>
					<CategoryCard
						v-if="data.href !== 'all' && data.image"
						:data="data"
						:redirect="collectionToNode(data.href)"
						:image-src="data.image"
						:title="data.name"
						:horizontal-index="index"
						:vertical-index="idx"
						:data-source="{
							dataSource: 'manual',
							version: '',
							clickedFrom: 'homepage',
						}"
						shape="rectangle"
					/>
				</CategoryCarousel>

				<CategoryCarouselScrollable
					v-if="section.component_identifier === 'category-carousel-scrollable'"
					v-slot="{ index, data }"
					:metadata="cast<EntityData>(section.meta_data)"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				>
					<CategoryCard
						v-if="data.href !== 'all' && data.image"
						:data="data"
						:redirect="collectionToNode(data.href)"
						:image-src="data.image"
						:title="data.name"
						:horizontal-index="index"
						:vertical-index="idx"
						:data-source="{
							dataSource: 'manual',
							version: '',
							clickedFrom: 'homepage',
						}"
						shape="square"
					/>
				</CategoryCarouselScrollable>

				<CategoryGridScrollable
					v-if="section.component_identifier === 'category-grid-scrollable'"
					:data="section.meta_data.output"
					:metadata="cast<EntityData>(section.meta_data)"
					:section-index="verticalIndex"
				>
					<template #default="{ data, index }">
						<CategoryCard
							v-if="data.href !== 'all' && data.image"
							:data="data"
							:redirect="collectionToNode(data.href)"
							:image-src="data.image"
							:title="data.name"
							:horizontal-index="index"
							:vertical-index="idx"
							:data-source="{
								dataSource: 'manual',
								version: '',
								clickedFrom: 'homepage',
							}"
							shape="square"
						/>
					</template>
				</CategoryGridScrollable>
				<MiniBanner
					v-if="section.component_identifier === 'mini-banner'"
					:data="section.meta_data.output"
					:section-index="verticalIndex"
				/>
			</template>
		</template>

		<div
			class="infinite_loader flex items-center justify-center text-gray-300"
			:class="{
				'p-lg': hasNextPage,
			}"
		>
			{{ hasNextPage ? 'Loading more sections...' : '' }}
		</div>
		<ClientOnly
			v-if="!hasNextPage && storeType == 'MARKETPLACE' && pageIdentifier"
		>
			<div>
				<TrendingProducts
					:node-id="
						nodeIdMapToIdentifier[
							pageIdentifier as keyof typeof nodeIdMapToIdentifier
						]
					"
				/>
			</div>
		</ClientOnly>
	</div>
</template>

<style>
.visually-hidden {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(0px 0px 99.9% 99.9%);
	clip-path: inset(0px 0px 9% 9%);
	overflow: hidden;
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0;
}
</style>
